import React from 'react';
import i18n from '../i18n';

export default class Team extends React.Component {
    render(){
        return(
            <section className="page-section" id="team">
              <div className="container">
                <div className="text-center">
                  <h2 className="section-heading text-uppercase">{i18n.t('ContactUsHeader')} </h2>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="team-member">
                      <img className="mx-auto rounded-circle" style={{borderRadius: '50%', height:'16.5em', width: '12.5em' }} src="assets/img/team/janisliz.jpg" alt="links" />
                      <h4>Jānis Lizanders</h4>
                      <p className="text-muted">{i18n.t('OwnerRank')}</p>
                      <a className="btn btn-dark btn-social mx-2" href="mailto:janis.lizanders@dtg.lv"><i className="fa fa-envelope" /></a><a className="btn btn-dark btn-social mx-2" href="#!"  data-toggle="tooltip" data-placement="bottom" title="+ 371 29556611"><i className="fa fa-phone" /></a>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="team-member">
                      <img className="mx-auto rounded-circle" style={{borderRadius: '50%', height:'16.5em', width: '12.5em' }} src="assets/img/team/kristapsrudz.jpg" alt="links" />
                      <h4>Kristaps Rudzītis</h4>
                      <p className="text-muted">{i18n.t('ITRank')}</p>
                      <a className="btn btn-dark btn-social mx-2" href="mailto:kristaps.rudzitis@dtg.lv"><i className="fa fa-envelope" /></a><a className="btn btn-dark btn-social mx-2" href="#!" data-toggle="tooltip" data-placement="bottom" title="+ 371 26222261" ><i className="fa fa-phone"/></a>
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
                    <div className="team-member">
                    <div>
                      <img className="mx-auto rounded-circle" style={{borderRadius: '50% !important', height:'16.5em', width: '12.5em' }} src="assets/img/team/kristina.jpg" alt="links" />
                      </div>
                      <h4>Kristīne Mežaka</h4>
                      <p className="text-muted">{i18n.t('SalesmanRank')}</p>
                      <a className="btn btn-dark btn-social mx-2" href="mailto:kristine.mezaka@dtg.lv"><i className="fa fa-envelope" /></a><a className="btn btn-dark btn-social mx-2" href="#!" data-toggle="tooltip" data-placement="bottom" title="+ 371 26099881"><i className="fa fa-phone" /></a>
                    </div>
                  </div> */}

                  <div className="col-lg-4">
                    <div className="team-member">
                      <img className="mx-auto rounded-circle" style={{borderRadius: '50%', height:'16.5em', width: '12.5em' }} src="assets/img/team/grube.jpg" alt="links" />
                      <h4>Māris Grūbe</h4>
                      <p className="text-muted">{i18n.t('SalesManagerRank')}</p>
                      <a className="btn btn-dark btn-social mx-2" href="mailto:maris.grube@dtg.lv"><i className="fa fa-envelope" /></a><a className="btn btn-dark btn-social mx-2" href="#!" data-toggle="tooltip" data-placement="bottom" title="+ 371 29114117"><i className="fa fa-phone" /></a>
                    </div>


                  </div>
                </div>
              </div>
            </section>
        )
    }
}
