import React from 'react';
import i18n from '../i18n';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: 3,
            show: false,
            selectedView: '---',
            status: '',
            form: {

            }
        };
        this.startIThrown = this.startIThrown.bind(this);
    }
    startIThrown(){
      document.location = 'waze://?ll=56.98000523%2C24.11761880&navigate=yes';
      setTimeout(() => {
        if(window.confirm('Jūsu ierīcē nav ielādēts waze, vai vēlaties atvērt iekš WEB?')){
          window.open('https://waze.com/ul?ll=56.98000523%2C24.11761880&navigate=yes');
        }
    }, 5000);
    }
    onSubmit = (e) => {
        e.preventDefault();
       const {form, secret} = this.state;
        fetch(`https://www.dtg.lv/api/sendEmail`, {
          method: 'POST',
          headers:{
              'Accept': 'application/json',
              'Content-Type':'application/json',
          },
          body: JSON.stringify({form, secret})
      }).then(res => res.json()).then((response) => {
          if (response.status === 'error') {
              this.setState({errorMsg: `${i18n.t('FormError')}`})
          } else {
             this.handleReset();
             this.setState({show: false, status: 'success'})
          }

        })
    }
    onChange = (e) => {
        const {name, value } = e.target;
        e.preventDefault();
        let updatedContent;
             updatedContent = Object.assign({}, this.state.form, {[name]: e.target.value})

            this.setState({form : updatedContent})



    }
    handleReset = () => {
  Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
  );
  this.setState({
    itemvalues: [{}]
  });
};
    render(){
        const { selectedView } = this.state
          const VIEWS = [
              {
                name: '---',
                minor: ['--'],
              },
            {
              name: 'Programmatūras izstrāde',
              minor: ['---', 'Pielāgoti risinājumi', 'Uzņēmuma procesu pārvaldības risinājumi', 'Datu uzskaites un analītikas risinājumi', 'Piekļuves kontroles risinājumi'],
            }, {
              name: 'Drošības sistēmas',
              minor: ['---', 'Videonovērošana', 'Auto numuru atpazīšana', 'Piekļuves kontrole', 'Apziņošanas sistēma (UAS)'],
            }
            , {
              name: 'Telekomunikācijas',
              minor: ['---','Internets', 'Telefonija', 'E-Pasts', 'Hostinga pakalpojumi'],
            }
            , {
              name: 'Tīklu izbūve',
              minor: ['---','Vara kabeļi', 'Optiskie kabeļi', 'Bezvadu tīkli', 'Vājstrāvu (LAN) tīkli'],
            }
          ]
          const view = VIEWS.filter(({name}) => name === selectedView)[0]
        return(
            <section className="dark-bg text-center section-padding contact-wrap" id="contact" style={{height: '100%', padding: '20px'}}>
              <div className="container">
                <div className="row contact-details">
                <div className="col-md-12 col-sm-12" style={{backgroundColor:'white', marginBottom: 25}} >
                <div style={{cursor: 'pointer',  padding: 15}} onClick={() => { this.setState({show: !this.state.show})}}>
                <h2 className="headings headings-yellow col-md-12 col-sm-12" style={{ flex: 1,color: 'black', marginTop: '10px', fontSize: '1.35rem'}} >
                    {i18n.t('FormHeader')}
                    <a data-toggle="collapse" href="#collapseExample"
                    aria-expanded="false" aria-controls="collapseExample">
                    {this.state.status === 'success' ?
                    <i style={{float: 'left', color: 'green'}} className="fa fa-check"></i>
                    :<i style={{float: 'left', color: 'transparent'}} className="fa fa-check"></i>}
                    {this.state.show === false ?
                        <i style={{float: 'right'}} className="fa fa-chevron-right pull-right"></i>
                        : <i style={{float: 'right'}}  className="fa fa-chevron-down pull-right"></i>
                    }
                    </a>
                </h2>
                </div>
                <Collapse  isOpen={this.state.show}>

                    <Card  style={{border: 'none'}}>
                      <CardBody >
                      <div className="container col-lg-12 col-md-12 col-xs-12">
                      <form action="#" onInvalid={(e) => e.target.setCustomValidity('Enter User Name Here')}>
                           <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="col-md-6 col-xs-12">
                                        <div className="row">
                                            <div className="forms-field  col-md-12 col-xs-12">
                                                <p>{i18n.t('FormOrganization')}*</p>
                                                <input type="text" placeholder=""  name='Organizācija' required onChange={this.onChange}/>
                                            </div>
                                            <div className="forms-field  col-md-12 col-xs-12">
                                                <p>{i18n.t('FormName')}*</p>
                                                <input type="text" placeholder="" name="Vārds, Uzvārds"  onChange={this.onChange}  required/>
                                            </div>
                                            <div className="forms-field col-md-12 col-xs-12">
                                                <p>{i18n.t('FormPhone')}*</p>
                                                <input type="text"  name='Telefons' required onChange={this.onChange} placeholder="" />
                                            </div>
                                            <div className="forms-field col-md-12 col-xs-12">
                                                <p>{i18n.t('FormEmail')}</p>
                                                <input type="text" name='E-Pasts' onChange={this.onChange} placeholder="" />
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                        <div className="row">
                                            <div className="forms-field col-md-12 col-xs-12">
                                                <p>{i18n.t('FormCategory')}</p>
                                                <select name="Kategorija" onChange={(e) => {this.onChange(e); this.setState({selectedView: e.target.value})}}>
                                                {VIEWS.map(({name}, index) => <option value={name} key={name || index}>{name}</option>)}


                                                </select>
                                            </div>

                                            <div className="forms-field  col-md-12">
                                                <p>{i18n.t('FormService')}</p>
                                                <select name='Pakalpojums' disabled={selectedView === '---'} onChange={this.onChange} >
                                                 {view.minor.map(m => <option value={m} key={m}>{m}</option>)}
                                                </select>
                                            </div>

                                            <div className="forms-field col-md-12 col-xs-12">
                                                <p>{i18n.t('FormRole')}</p>
                                                <input type="text" name="Amats" onChange={this.onChange} placeholder="" />
                                            </div>
                                            <div className="forms-field  col-md-12">
                                                <p>{i18n.t('FormComment')}</p>
                                                <textarea  type="text" name="Komentārs" placeholder="" onChange={this.onChange} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex'}} className="forms-field col-md-12 col-xs-12">
                                    <p className=' col-md-4 col-xs-3'>{i18n.t('FormCaptcha')}</p>
                                    <input type="text" className=' col-md-4 col-xs-4' onChange={(e) => {this.setState({secret: e.target.value})}} placeholder="" />
                                    <div className=' col-md-4 col-xs-3'></div>
                                </div>
                                <h5>{this.state.errorMsg}</h5>
                                <button className="btn" type='button' onClick={this.onSubmit}>{i18n.t('FormButton')}</button>
                                </form>
                            </div>
                      </CardBody>
                    </Card>
                  </Collapse>


                </div>


                  <div className="col-md-4 col-sm-12">
                  <a  href="#" onClick={()=> {this.startIThrown()}} id="driveto" target="_blank">
                    <div className="light-box box-hover" style={{marginBottom: '5%'}}>
                      <h2 id="credits"><i className="fas fa-compass" /><span>{i18n.t('Adress')}</span></h2>
                      <p> Rīga, Ganību dambis 24A- 52, LV - 1005</p>
                    </div>
                    </a>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="light-box box-hover" style={{marginBottom: '5%'}}>
                      <h2 id="credits"><i className="fas fa-mobile-alt" /><span>{i18n.t('Phone')}</span></h2>
                      <p><a href="tel:67501421" id="mailto">+371 67501421</a></p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="light-box box-hover" style={{marginBottom: '5%'}}>
                      <h2 id="credits"><i className="fa fa-paper-plane" /><span>{i18n.t('Email')}</span></h2>
                      <p><a href="mailto:sales@dtg.lv" id="mailto">sales@dtg.lv</a></p>
                    </div>
                  </div>

                </div>
              </div>
            </section>
        )
    }
}
