import React from 'react';
import i18n from '../i18n';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default class Slider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: 1,
        };
    }

    changeLanguage(lng) {
        console.log('CHANGE')
        i18n.changeLanguage(lng);

    }
componentDidMount(){
    this.setState({language: i18n.language})
}
    render(){
        const options = {
            items:1,
            autoplay: true,
            autoplayTimeout:4000,
            loop: true,
            className:'owl-carousel',
            smartSpeed: 700,
            navSpeed:700,
            dotsSpeed:700,
            lazyLoad: true,
            lazyContent: true,
};
        return(

            <OwlCarousel
                options={options}
            >
              {/* {console.log(options)} */}
            <header className="masthead">
            <picture>
              <source srcSet="../assets/img/slider1.jpg" type="image/jpg" />
              <source srcSet="../assets/img/slider1.jpg" type="image/jpg" />
              <img className="img-fluid" src="../assets/img/slider1.jpg"style={{position: 'absolute', filter: 'brightness(100%)', top: '0px',width: '100%',minHeight: "100%" , height: "-webkit-fill-available", objectFit: 'cover', backgroundColor: 'white'}}  alt="links" />
            </picture>
              <div id="carouselExampleIndicators"  className="carousel slide" data-ride="carousel">

                <div className="row">
                <div className="col-md-12">
                  <div className="carousel-item active">
                    <div className="container">
                     <div className="masthead-heading text-uppercase" style={{ width: '57vw', fontSize: '3.5vh', letterSpacing: '0.3rem', lineHeight: '5vh'}}>{i18n.t('Slider2Sub')}<br />{i18n.t('Slider2Head')}</div>
                      <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">{i18n.t('MoreBtn')}</a>
                    </div>
                  </div>
                  </div>

                  </div>
                </div>

            </header>
            <header className="masthead">
            <picture>
              <source srcSet="../assets/img/slider0.jpg" type="image/jpg" />
              <source srcSet="../assets/img/slider0.jpg" type="image/webp" />
              <img className="img-fluid" src="../assets/img/slider0.jpg"style={{position: 'absolute', filter: 'brightness(40%)',  top: '0px',width: '100%',minHeight: "100%" , height: "-webkit-fill-available", objectFit: 'cover', backgroundColor: 'white'}}  alt="links" />
            </picture>
             <div id="carouselExampleIndicators"  className="carousel slide" data-ride="carousel">
                <div className="row">
                <div className="col-md-12">
                  <div className="carousel-item active">
                    <div className="container">
                     <div className="masthead-heading text-uppercase" style={{ width: '57vw', fontSize: '3.5vh', letterSpacing: '0.3rem', lineHeight: '5vh'}}>{i18n.t('Slider1Sub')}<br />{i18n.t('Slider1Head')}</div>
                      <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">{i18n.t('MoreBtn')}</a>
                    </div>
                  </div>
                  </div>

                  </div>
                </div>
            </header>
            <header className="masthead">
            <picture>
              <source media="(min-width:650px)" srcSet="../assets/img/slider2.jpg" type="image/jpg" />
              <source srcSet="../assets/img/slider2.jpg" type="image/jpg" />
              <img className="img-fluid" src="../assets/img/slider2.jpg"style={{position: 'absolute', filter: 'brightness(40%)', top: '0px',width: '100%',minHeight: "100%" , height: "-webkit-fill-available", objectFit: 'cover', backgroundColor: 'white'}}  alt="links" />
            </picture>             <div id="carouselExampleIndicators"  className="carousel slide" data-ride="carousel">

               <div className="row">
               <div className="col-md-12">
                 <div className="carousel-item active">
                   <div className="container">
                    <div className="masthead-heading text-uppercase" style={{ width: '57vw', fontSize: '3.5vh', letterSpacing: '0.3rem', lineHeight: '5vh'}}>{i18n.t('Slider3Sub')}<br />{i18n.t('Slider3Head')}</div>
                     <a href="https://workhour.eu/" target="_blank" className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" style={{marginBottom: '12vh'}}>Workhour.eu</a>
                   </div>
                 </div>
                 </div>

                 </div>
               </div>

           </header>

              </OwlCarousel>

        )
    }
}
